import { COMPANIES_TYPES_LABELS } from "constants/types";
import { Badge } from "reactstrap";
import { formatCnpj } from "utils";

export const CustomCustomerOption = ({ data: { data } }) => {
  const matrizOrFilial = cnpj => {
    if (!cnpj) {
      return { text: "CNPJ não informado", color: "light-secondary" };
    }

    if (cnpj?.substring(8, 12) === "0001") {
      return { text: "MATRIZ", color: "info" };
    }

    return { text: "FILIAL", color: "light-warning" };
  };

  const matrizFilialInfo = matrizOrFilial(data?.cli_cnpj);

  return (
    <>
      <div className="d-flex flex-column">
        <div className="d-flex align-items-center justify-content-between">
          <small className="fw-bolder fs-6">{data?.cli_nome}</small>
          <Badge color="warning">{data?.cli_uf}</Badge>
        </div>
        <div>
          <small>REGIME FISCAL: &nbsp;</small>
          <small>
            {COMPANIES_TYPES_LABELS[data?.cli_tributacao] || "Não informado"}
          </small>
        </div>
      </div>
      <div className="mt-1 d-flex align-items-center justify-content-between ">
        <div className="d-flex align-items-center gap-1">
          <Badge size="sm" color={matrizFilialInfo.color}>
            {matrizFilialInfo.text}
          </Badge>
          <Badge color={data?.cli_ativo ? `success` : `danger`}>
            {data?.cli_ativo ? "ATIVO" : "INATIVO"}
          </Badge>
        </div>
        {data?.cli_cnpj && (
          <Badge pill color="secondary">
            CNPJ: {formatCnpj(data?.cli_cnpj)}
          </Badge>
        )}
      </div>
    </>
  );
};
