import React from "react";
import Flatpickr from "react-flatpickr";
import { Portuguese } from "flatpickr/dist/l10n/pt";
import { Format } from "utils";

export const Date = ({ value, handleOnChange }) => {
  return (
    <div>
      <input type="hidden" className="date" name="mother" />
      <Flatpickr
        options={{
          locale: Portuguese,
          mode: "range",
          dateFormat: "Y-m-d",
          formatDate: value => {
            return value > 0
              ? Format(value, "dd/MM/yyyy")
              : "Carregando data...";
          },
        }}
        className="form-control ms-50 pe-3 w-100 text-primary"
        value={value}
        onChange={date => handleOnChange(date)}
        id="default-picker"
        placeholder="00-00-0000"
      />
    </div>
  );
};
