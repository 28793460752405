import { createSlice } from "@reduxjs/toolkit";

export const initialModules = {
  listModules: [],
  modules: {},
  params: {
    page: 1,
    limit: 10,
  },
};

const initialState = () => {
  return initialModules;
};

export const modulesSlice = createSlice({
  name: "modules",
  initialState,
  reducers: {
    handleListNewModule: (state, { payload }) => {
      const copy = [...state.listModules];

      const newItems = payload;

      state.listModules = [...copy, newItems];
    },
    handleNewModule: (state, { payload }) => {
      state.modules = payload;
    },
    handleListNewModuleList: (state, { payload }) => {
      state.listModules = payload;
    },
    handleChangeUpdate: (state, { payload }) => {
      const list = [...state.listModules];
      list[payload?.key][payload?.field] = payload?.value;

      state.listModules = list;
    },
    handleDeleteModule: (state, { payload }) => {
      const copy = [...state.listModules];

      const remove = copy.filter((item, key) => key !== payload?.key);

      state.listModules = [...remove];
    },
    handleRowLimit: (state, { payload }) => {
      state.params.limit = payload;
    },
    handleFilter(state, { payload }) {
      const field = payload?.field;
      state.params[field] = payload?.value;
      return state;
    },
  },
});

export const {
  handleNewModule,
  handleListNewModule,
  handleDeleteModule,
  handleListNewModuleList,
  handleChangeUpdate,
  handleRowLimit,
  handleFilter,
} = modulesSlice.actions;

export default modulesSlice.reducer;
