import navbar from "./navbar";
import layout from "./layout";
import auth from "./authentication";

import app from "./app";
import dashboard from "./dashboard";
import product from "./product";
import productsClient from "./productClients";
import productsRelatory from "./productsRelatory";
import invoice from "./invoice";
import repricing from "./repricing";
import oportunidade from "./oportunidade";
import cest from "./cest";
import ncm from "./ncm";
import stores from "./stores";
import upload from "./Upload";
import users from "./users";
import accessGroups from "./accessGroups";
import dialogAlert from "./DialogAlert/dialog";
import alertness from "./alertness";
import storeStatus from "./Stores/status";
import taxAnalises from "./taxAnalises";
import internoListaClientes from "./interno/lista-clientes";
import storesForms from "./Stores/storesForms";
import fiscalDiagnosisProject from "./fiscalDiagnosis/project";
import diagnosticoDashboard from "./fiscalDiagnosis/dashboard";
import relatorioSkus from "./relatorioSkus";
import relatorioCfopCsts from "./relatorioCfop";
import relatorioDocumentosFiscais from "./Relatorio/documentosFiscais";
import countFilesCnpjs from "./countFiles";
import cnpjTotals from "./cnpjTotals";
import politicPrice from "./politicScreen";
import classificar from "./classificar";
import integrations from "./integrations";
import speds from "./speds";
import integrationsConfigs from "./integrations/configs";
import integrationSolicitation from "./integrations/solicitar";
import appEscrituracao from "./EscrituracaoFiscal/appEscrituracao";
import escrituracaoFiscalList from "./EscrituracaoFiscal/list";
import escrituracaoAssociation from "./EscrituracaoFiscal/association";
import escrituracaoAssociationNotesPendentes from "./EscrituracaoFiscal/association-notas-pendentes";
import parameterConfigurator from "./EscrituracaoFiscal/parameterConfigurator";
import escriturationClassification from "./EscrituracaoFiscal/classification";
import modules from "./modules";
import tagsXmls from "./tagsXmls";
import marketplace from "./marketplace/marketplace";
import robot from "./Suporte/Robot/robot";
import resend from "./reenvios";
import actionsNotes from "./EscrituracaoFiscal/actionsNote";
import atualizacoes from "./atualizacoes";
import produtoEanGtin from "./productEanGtin";
import painelCmf from "./Suporte/painel/painel";
import metas from "./metas";
import contractCategory from "./contract/category";
import reportsEscrituration from "./EscrituracaoFiscal/reports";
import escrituracaoDash from "./EscrituracaoFiscal/escrituracaoDash";
import heatMap from "./heatMap";
import portaria from "./legislacao/portaria";
import notificationAll from "./configs/notifications";
import contratos from "./contratos";

import formContratos from "./contratos/formContrato";
import icms from "./icms";
import classificacaoDireta from "./classificacaoDireta";
import boxAndRisk from "./boxAndRisk";
import percentualEscrituracao from "./EscrituracaoFiscal/percentual";
import productReportsInvoices from "./Relatorio/productReportsInvoices";
import cbenef from "./legislacao/cbenef";
import regrasTributarias from "./legislacao/regrasTributarias";
import bluesoftFigurasFiscais from "./integrations/bluesoft/figurafiscal";
import categoria from "./legislacao/categoria";
import casosEspeciais from "./legislacao/casosEspeciais";
import invoiceReceipt from "./EscrituracaoFiscal/invoiceReceipt";
import listaFinanceira from "./Financas/listaFinanceira";
import produtosImpactados from "./legislacao/produtosImpactados";
import reverdescricao from "./reverDescricao";
import classificacaoIcms from "./legislacao/classificacaoIcms";
import especificacoes from "./legislacao/especificacoes";
import checklist from "./checklist";
import espValores from "./legislacao/espValores";
import produtos from "./legislacao/produtos";
import grupoEspecificacoes from "./legislacao/grupoEspecificacoes";
import generalAnalytics from "./fiscalDiagnosis/generalAnalytics";
import legisCest from "./legislacao/cest";
import notifications from "./notifications";
import legisDash from "./legislacao/dash";
import classificacaoFiscal from "./classificacaoFiscal";
import taxLogs from "./taxLogs";
import relatorioProdutos from "./relatorioProdutos";
import painelMachine from "./painelMachine";
import systemConfigs from "./systemConfigs";

const rootReducer = {
  auth,
  cest,
  users,
  navbar,
  layout,
  app,
  dashboard,
  product,
  productsClient,
  invoice,
  repricing,
  oportunidade,
  upload,
  users,
  ncm,
  accessGroups,
  stores,
  dialogAlert,
  alertness,
  storeStatus,
  productsRelatory,
  taxAnalises,
  internoListaClientes,
  storesForms,
  fiscalDiagnosisProject,
  diagnosticoDashboard,
  relatorioSkus,
  relatorioCfopCsts,
  relatorioDocumentosFiscais,
  countFilesCnpjs,
  cnpjTotals,
  politicPrice,
  classificar,
  integrations,
  speds,
  integrationsConfigs,
  integrationSolicitation,
  escrituracaoFiscalList,
  escrituracaoAssociation,
  appEscrituracao,
  escrituracaoAssociationNotesPendentes,
  parameterConfigurator,
  escriturationClassification,
  modules,
  tagsXmls,
  marketplace,
  robot,
  resend,
  actionsNotes,
  atualizacoes,
  produtoEanGtin,
  painelCmf,
  metas,
  contractCategory,
  reportsEscrituration,
  escrituracaoDash,
  heatMap,
  portaria,
  notificationAll,
  contratos,
  formContratos,
  regrasTributarias,
  icms,
  classificacaoDireta,
  boxAndRisk,
  percentualEscrituracao,
  productReportsInvoices,
  cbenef,
  bluesoftFigurasFiscais,
  categoria,
  casosEspeciais,
  invoiceReceipt,
  listaFinanceira,
  produtosImpactados,
  reverdescricao,
  notifications,
  legisDash,
  classificacaoFiscal,
  taxLogs,
  classificacaoIcms,
  especificacoes,
  checklist,
  espValores,
  relatorioProdutos,
  produtos,
  grupoEspecificacoes,
  generalAnalytics,
  legisCest,
  painelMachine,
  systemConfigs,
};

export default rootReducer;
