import React, { useEffect, useState } from "react";

// ** Logo
import logo from "@src/assets/images/logo/logo.png";
import { useSelector } from "react-redux";

const SpinnerComponent = () => {
  const { systemData } = useSelector(state => state.systemConfigs);
  const [logoVar, setLogoVar] = useState();

  useEffect(() => {
    setLogoVar(systemData?.logo_full ?? logo);
  }, [systemData]);

  return (
    <div className="fallback-spinner app-loader">
      {systemData && (
        <img
          className="fallback-logo"
          src={logoVar}
          alt="logo"
          style={{ maxWidth: "16rem" }}
        />
      )}
      <div className="loading">
        <div className="effect-1 effects"></div>
        <div className="effect-2 effects"></div>
        <div className="effect-3 effects"></div>
      </div>
    </div>
  );
};

export default SpinnerComponent;
