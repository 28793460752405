import axios from "axios";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getSessionCompanies } from "utils";

const MIX_API =
  process.env.REACT_APP_MIX_API ||
  "https://api.interno.mixfiscal.com.br/interno/v1/";

const api = axios.create({
  baseURL: MIX_API,
});

api.interceptors.request.use(config => {
  config.headers.Authorization = getSessionCompanies();
  return config;
});

api.interceptors.response.use(
  response => response,
  error => Promise.reject(error),
);

export const fetchSystemConfigs = createAsyncThunk(
  "systemConfigs/fetchSystemConfigs",
  async (_, thunkAPI) => {
    try {
      const response = await api.get(
        `/configs/system_config?my_domain=${window.location.hostname}`,
      );
      return response.data;
    } catch (error) {
      console.error("Erro ao buscar systemData:", error);
      return thunkAPI.rejectWithValue(error.message);
    }
  },
);

const initialState = {
  systemData: null,
  loading: false,
  error: null,
};

export const storeSlice = createSlice({
  name: "systemConfigs",
  initialState,
  reducers: {
    handleSystemConfigs: (state, { payload }) => {
      state.systemData = payload;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchSystemConfigs.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchSystemConfigs.fulfilled, (state, action) => {
        state.loading = false;
        state.systemData = action.payload;
      })
      .addCase(fetchSystemConfigs.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { handleSystemConfigs } = storeSlice.actions;

export default storeSlice.reducer;
