import React from "react";
import { components } from "react-select";
import { Controller } from "react-hook-form";

import AsyncSelect from "react-select/async";

import { Label } from "reactstrap";

import { OptionsComponentStore } from "./OptionCustom";
import { CustomCustomerOption } from "./OptionCustom/customer";

const CustomOptionStore = ({ children, ...props }) => {
  return (
    <components.Option {...props}>
      <OptionsComponentStore data={props} />
    </components.Option>
  );
};

const CustomOptionCustomer = ({ children, ...props }) => {
  return (
    <components.Option {...props}>
      <CustomCustomerOption data={props} />
    </components.Option>
  );
};

const CustomOptionDefault = ({ children, ...props }) => {
  return <components.Option {...props}>{children}</components.Option>;
};

const componentsOptions = {
  store: CustomOptionStore,
  cliente: CustomOptionCustomer,
  default: CustomOptionDefault,
};

export const SelectAsync = props => {
  const {
    type = "default",
    label = "",
    options,
    control,
    required,
    name,
    defaultValue,
  } = props;

  if (typeof options !== "function") {
    return "Inform os options para a busca";
  }

  const componentOption = componentsOptions[type];

  const resolveSearch = value => {
    const isText = /[a-zA-Z]+/.test(value);
    const isCnpj = /[\d./-]/.test(value);
    if (isCnpj && !isText) {
      const resultado = value.replace(/[./-]/g, "");
      return resultado ?? null;
    }
    return value ?? null;
  };

  const promiseOptions = inputValue => {
    let search = inputValue;
    if (type === "store" || "cliente") {
      search = resolveSearch(inputValue);
    }

    return new Promise(resolve => {
      setTimeout(() => {
        resolve(options(search));
      }, 2000);
    });
  };

  if (control) {
    return (
      <>
        <Label>{label}</Label>
        <Controller
          rules={{ required }}
          control={control}
          name={name}
          defaultValue={defaultValue}
          render={({ field, value, defaultValue: defaultValue2 }) => (
            <AsyncSelect
              components={{
                Option: componentOption,
              }}
              loadOptions={promiseOptions}
              {...props}
              defaultValue={defaultValue2}
              {...field}
            />
          )}
        />
      </>
    );
  }

  return (
    <>
      <Label>{label}</Label>
      <AsyncSelect
        components={{
          Option: componentOption,
        }}
        loadOptions={promiseOptions}
        {...props}
      />
    </>
  );
};
