import React, { Fragment } from "react";
import { Label } from "reactstrap";
import { Controller } from "react-hook-form";
import Flatpickr from "react-flatpickr";
import { Portuguese } from "flatpickr/dist/l10n/pt";
import { Format } from "utils";

export const DateSimples = ({
  value,
  title = "Data inicial",
  name = "start",
  control,
  setValue,
}) => {
  return (
    <Fragment>
      <Label type="h3" className="mx-1">
        {title}
      </Label>
      <Controller
        name={name}
        control={control}
        render={() => (
          <>
            <input type="hidden" className="date" name={name} />
            <Flatpickr
              options={{
                locale: Portuguese,
                dateFormat: "Y-m-d",
                formatDate: value => {
                  return value > 0
                    ? Format(value, "dd/MM/yyyy")
                    : "Carregando data...";
                },
              }}
              className="form-control ms-50 pe-3 w-100 text-primary"
              value={value}
              onChange={e => setValue(name, e[0])}
              id="default-picker"
            />
          </>
        )}
      />
    </Fragment>
  );
};
